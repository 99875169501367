<template>
	<div>
		<div class="ordermanagebox">

			<el-card>
				<div class="global-search-box">
					<div class="search-content">
						<div class="search-item">
							<el-input :placeholder="orderref == 1 ? '请输入订单编号' : '请输入政采编号'" v-model="orderefName">
								<template slot="prepend">
									<el-select v-model="orderref" placeholder="请选择">
										<el-option v-for="(item, index) in ordersearchList" :key="item.value"
											:label="item.label" :value="item.value"></el-option>
									</el-select>
								</template>
							</el-input>
						</div>
						<div class="search-item">
							<el-input :placeholder="shopnamesku == 1 ? '请输入商品名称' : '请输入商品sku'" v-model="ordershopname">
								<template slot="prepend">
									<el-select v-model="shopnamesku" placeholder="请选择">
										<el-option v-for="(item, index) in ordershopList" :key="item.value"
											:label="item.label" :value="item.value"></el-option>
									</el-select>
								</template>
							</el-input>
						</div>
						<div class="search-item">
							<el-input
								:placeholder="valuesecond == 1 ? '请输入收货人姓名' : valuesecond == 2 ? '请输入收货人电话' : valuesecond == 3 ? '请输入发票抬头' : ''"
								v-model="valuename">
								<template slot="prepend">
									<el-select v-model="valuesecond" placeholder="请选择">
										<el-option v-for="(item, index) in valuesecondlist" :key="item.value"
											:label="item.label" :value="item.value"></el-option>
									</el-select>
								</template>
							</el-input>
						</div>
						<div class="search-item">
							<label class="search-label">订单类型：</label>
							<el-select v-model="ordertype" filterable clearable placeholder="请选择">
								<el-option v-for="(item, index) in common_info['order.type.list']" :key="item.value"
									:label="item.value" :value="item.key"></el-option>
							</el-select>
						</div>
						<div class="search-item">
							<label class="search-label">订单来源：</label>
							<!-- <el-select v-model="ordersource" filterable clearable placeholder="请选择"
								@change="addhandleChange">
								<el-option v-for="(item, index) in common_info['order.source.type.list']" :key="index"
									:label="item.value" :value="item.key" :type="item.type">
								</el-option>
							</el-select> -->
							<el-cascader v-model="ordersource" :props="{ checkStrictly: true }"
								:options="common_info['order.source.list']" @change="addhandleChange">
							</el-cascader>
						</div>
						<div class="search-item">
							<label class="search-label">商品类型：</label>
							<el-select v-model="shoptype" clearable placeholder="请选择">
								<el-option v-for="(item, index) in common_info['goods.vr_type.list']" :key="index"
									:label="item.value" :value="item.key"></el-option>
							</el-select>
						</div>
						<template v-if="is_filter">
							<div class="picker-date-picker">
								<el-select v-model="valuetime" placeholder="请选择" @change="ordertimechange">
									<el-option v-for="(item, index) in timeList" :key="item.value" :label="item.label"
										:value="item.value"></el-option>
								</el-select>
								<el-date-picker v-model="valuetimes" type="datetimerange" start-placeholder="开始日期"
									format="yyyy-MM-dd" end-placeholder="结束日期" :picker-options="pickerOptions"
									:default-time="['00:00:00', '23:59:59']">
								</el-date-picker>
							</div>
							<div class="search-item">
								<label class="search-label">售后状态：</label>
								<el-select v-model="servicestatus" clearable placeholder="请选择">
									<el-option v-for="(item, index) in  common_info['order.refund.status.list']"
										:key="item.key" :label="item.value" :value="item.key"></el-option>
								</el-select>
							</div>
							<div class="search-item">
								<label class="search-label">订单状态：</label>
								<el-select v-model="orderstatus" clearable placeholder="请选择">
									<el-option v-for="(item, index) in  common_info['order.status.list']" :key="item.key"
										:label="item.value" :value="item.key"></el-option>
								</el-select>
							</div>
							<div class="search-item">
								<label class="search-label">配送方式：</label>
								<el-select v-model="shipping" clearable placeholder="请选择">
									<el-option v-for="(item, index) in shippingList" :key="item.value" :label="item.label"
										:value="item.value"></el-option>
								</el-select>
							</div>
							<div class="search-item">
								<label class="search-label">支付方式：</label>
								<el-select v-model="paytype" clearable placeholder="请选择">
									<el-option v-for="(item, index) in common_info['order.payment.type.list']" :key="index"
										:label="item.value" :value="item.key"></el-option>
								</el-select>
							</div>
							<div class="search-item">
								<label class="search-label">员工账号：</label>
								<el-input v-model="account_number" placeholder="请输入员工账号" clearable></el-input>
							</div>
							<div class="search-item">
								<label class="search-label">省市区：</label>
								<div class="city-province">
									<el-select v-model="save" placeholder="省级" @change="choseProvince">
										<el-option v-for="(item, index) in citylisted" :key="index" :label="item.name"
											:value="item.id"></el-option>
									</el-select>
									<el-select v-model="cname" @change="choseCity" placeholder="市级">
										<el-option v-for="(item, $index) in city" :key="$index" :label="item.value"
											:value="item.id"></el-option>
									</el-select>
									<el-select v-model="bname" @change="choseBlock" placeholder="区级">
										<el-option v-for="(item, $index) in block" :key="$index" :label="item.value"
											:value="item.value"></el-option>
									</el-select>
								</div>
							</div>
							<!-- <div class="search-item">
								<label class="search-label">会员等级：</label>
								<el-select v-model="vipgrade" clearable placeholder="请选择">
									<el-option v-for="(item, index) in common_info['user.level_list']" :key="index"
										:label="item.name" :value="item.level"></el-option>
								</el-select>
							</div> -->
						</template>
						<div class="search-item">
							<div class="diy-search--more" @click="filtertab">
								<span>高级筛选</span>
								<i class="el-icon-arrow-down" v-if="!is_filter"></i>
								<i class="el-icon-arrow-up" v-else></i>
							</div>
						</div>
					</div>
					<div class="serach-buttom-button">
						<el-button type="primary" @click="ordersearched">搜索</el-button>
						<el-button @click="resetting">重置条件</el-button>
					</div>

				</div>
			</el-card>

			<div class="ordermanageboxbut">
				<div class="ordermanagenav ordermanagenavadd">
					<div class="ordermanagenavleft d-flex">

					</div>
					<div class="ordermanagenavright d-flex">
						<div class="manageleft d-flex">
							<span>排序：</span>
							<div class="orderselect mangeselect">
								<template>
									<el-select v-model="ordersort" placeholder="请选择" @change="timechange">
										<el-option v-for="(item, index) in timeList" :key="item.value" :label="item.label"
											:value="item.value"></el-option>
									</el-select>
								</template>
							</div>
						</div>
						<div class="manageright d-flex">
							<span :class="[sortIndex == 1 ? 'active' : '']" @click="ordersorted(1)">正序 <i
									class="el-icon-top"></i></span>
							<span :class="[sortIndex == 2 ? 'active' : '']" @click="ordersorted(2)">倒序 <i
									class="el-icon-bottom"></i></span>
						</div>
					</div>
				</div>
				<div v-if="tableData && tableData.length">
					<div class="ordertabel">
						<div class="ordertabeltitle clearfloat">
							<p>商品信息</p>
							<p>金额</p>
							<p>实际支付</p>
							<p>订单状态</p>
							<p>操作</p>
						</div>
						<div class="ordertabelcon">
							<div class="ordertabelist" v-for="(item, index) in tableData" :key="index">
								<div class="ordertabelistup clearfloat">
									<div class="ordertabelistleft">
										<p>
											<strong>订单来源：</strong>
											{{ item.company_category_title }}
										</p>
										<p>
											<strong>订单编号：</strong>{{ item.order_no }}
										</p>
										<p><strong>下单时间：</strong>{{ item.created_at }}</p>
										<p><strong>员工账号：</strong>{{ item.purchaser_name }}</p>

									</div>
									<div class="ordertabelistright clearfloat">
										<a href="javascript:void(0);" @click="loopdetail(item.id)"
											v-if="hasPerm(['order.show'])">查看详情</a>
										<a href="javascript:void(0);" @click.stop="recoverOrder(item)">恢复</a>
										<a href="javascript:void(0);" @click.stop="delOrder(item)">删除</a>
									</div>
								</div>
								<div class="ordertabelistcon d-flex">
									<div class="order_item">
										<div class="order_itemlist clearfloat"
											v-for="(itemed, indexed) in item.order_detail" :key="indexed"
											v-if="indexed < 3">
											<div class="order_itemimg">
												<img :src="itemed.pic" alt="" />
											</div>
											<div class="order_itemtext">
												<a href="javascript:void(0);">{{ itemed.goods_name }}</a>
												<div class="orderguige clearfloat">
													<p v-for="(orderitem, orderindex) in itemed.attr">
														<span>{{ orderitem.specs_key }}：</span>{{ orderitem.specs_value }}
													</p>
												</div>
												<div class="orderbutlast">
													<p><span>单价：</span>{{ itemed.price }}</p>
													<p><span>数量：</span>{{ itemed.num }}</p>
												</div>
											</div>
										</div>

										<div class="order_itembuts">
											<span>共 <strong>{{ item.detail_goods_count }}</strong> 种商品</span>
											<a href="javascript:void(0);" v-if="item.detail_goods_count >= 3"
												@click="loopdetail(item.id)">查看更多商品<i class="el-icon-arrow-right"></i></a>
										</div>
									</div>
									<div class="order_item d-flex align-items-center">
										<div class="order_itemoney">
											<p class="ormoney">{{ item.total_price }}</p>
											<p class="orderfare">运费：{{ item.express_price }}</p>
										</div>
									</div>
									<div class="order_item d-flex align-items-center">
										<div class="realitymoney">
											<p v-if="Number(item.account_price) > 0">积分：{{ item.account_price }}积分</p>
											<p v-if="Number(item.money_price) > 0">现金：{{ item.money_price }}元</p>
										</div>
									</div>
									<div class="order_item d-flex align-items-center justify-content-center">
										<div>
											<div class="orderstatused">
												<p v-for="(ites, ins) in item.status" :key="ins">{{ ites }}</p>
											</div>
											<div class="order_itemstatus">
												<p class="orderpays">{{
													common_info["order.payment.pay_type.map"][item.pay_type2] }}</p>
												<span class="ziti" v-if="item.is_offline == 1">自提</span>
												<span class="kuaidi" v-else>快递</span>
											</div>
										</div>
									</div>
									<div class="order_item d-flex align-items-center">
										<div class="order_operate">
											<div v-if="item.apply_delete == 1">
												<p v-if="item.is_revoke == 1">订单已退款</p>
												<p v-else>订单申请取消，审核中</p>
											</div>
											<div v-else>
												<div class="order_operatelast">
													<!-- <a href="javascript:void(0);" @click="orderdeliver(item.id, delivei, item)"
														v-for="(delivei, delivek) in item.menu" :key="delivek">{{
															delivei.title }}</a> -->
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="ordertabelistbuttom">
									<div class="ordertabelistbuttoms">
										<strong>收货信息：</strong>
										<p>
											<span>{{ item.receiver_name }}</span>
											<span>{{ item.receiver_mobile }}</span>
											<span>{{ item.address }}</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="orderlastbut clearfloat">
						<div class="orderlastbutright">
							<el-pagination @size-change="handleSizeChange" hide-on-single-page
								@current-change="handleCurrentChange" :current-page.sync="currentPage"
								:page-sizes="[10, 20, 30, 40]" :page-size="eachPage"
								layout="total,sizes, prev, pager, next, jumper" :pager-count="5" :total="total_entry">
							</el-pagination>
						</div>
					</div>
				</div>
				<template v-else>
					<div class="zntj" v-if="datatype">
						<img class="img-default" src="../../assets/images/home-empty.png" alt="" />
						<p>暂无数据</p>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	inject: ["reload"],
	data() {
		return {
			datatype: false,
			confirmQianshou: 0, // 确认签收
			confirmQianshouShow: false, // 确认签收弹窗
			confirmQianshouLoading: false, // 确认签收弹窗按钮loading
			ordertypes: false,
			valuesecond: "1", //收货人
			valuesecondlist: [{
				label: '收货人',
				value: '1',
			}, {
				label: '收货人电话',
				value: '2',
			}],
			valuename: '',
			servicestatus: "", //售后状态
			is_filter: false, //是否展开高级搜索
			orderstatus: "", //订单状态
			options: [{
				type: "",
				value: '1',
				label: '全部'
			}, {
				type: "",
				value: '2',
				label: 'PC商城'
			}, {
				type: "",
				value: '3',
				label: 'APP'
			}, {
				type: "",
				value: '4',
				label: '微信小程序'
			}, {
				type: "",
				value: '5',
				label: 'H5'
			}, {
				type: "secondary",
				value: '6',
				label: '政采订单'
			}, {
				type: "secondary",
				value: '7',
				label: '企业采购'
			}, {
				type: "secondary",
				value: '8',
				label: '积分福利'
			}],
			ordersecondary: false, //隐藏订单状态二级
			ordersecond: '', //二级显示菜单
			save: "", //省
			cname: "", //市
			city: [],
			bname: "", //区
			block: [],
			vipgrade: "", //会员等级

			orderref: "1", //订单编号和政采编号
			orderefName: "", //订单编号或政采编号名称
			shopnamesku: "1", //商品名称或SKU
			ordershopname: "", //商品名称或SKU搜索字段
			ordertype: "", //订单类型
			ordersource: "", //订单来源
			shoptype: "", //商品类型
			shipping: "", //配送方式
			valuetime: "id", //下单时间
			valuetimes: "", //日期选择
			ordertime: 'created_at', //头部时间搜索
			pickerOptions: {
				// 所有的截止时间为当天的23.59.59
				shortcuts: [{
					text: '今天',
					onClick(picker) {
						const start = new Date().setHours(0, 0, 0, 0);
						const end = new Date().setHours(23, 59, 59, 59);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '7天',
					onClick(picker) {
						const end = new Date().setHours(23, 59, 59, 59);
						const start = new Date();
						start.setTime(end - (3600 * 1000 * 24 * 7 - 1000));
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '30天',
					onClick(picker) {
						const end = new Date().setHours(23, 59, 59, 59);
						const start = new Date();
						start.setTime(end - (3600 * 1000 * 24 * 30 - 1000));
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			paytype: "", //支付方式
			ordersort: "id", //订单排序
			ordersortcon: 'desc', //正序倒序
			sortIndex: 2, //正序倒序的切换
			orderchecked: false, //是否选中订单列表
			currentPage: 1,
			eachPage: 10,
			total_entry: 0,
			journaltype: false, //控制日志是否显示
			ordersearchList: [{
				label: '订单编号',
				value: '1',
			},
			],
			ordershopList: [{
				label: '商品名称',
				value: '1',
			},
			],
			timeList: [{
				label: '按下单时间',
				value: 'id',
			}, {
				label: '按支付时间',
				value: 'pay_time',
			}, {
				label: '按发货时间',
				value: 'send_time',
			}, {
				label: '按完成时间',
				value: 'confirm_time',
			}],
			shippingList: [{
				label: '快递发货',
				value: '0',
			}, {
				label: '自提',
				value: '1',
			}],
			activeName: 0,
			tableData: [],
			citylisted: [],
			loglist: [], //日志列表
			pages: 1,
			limit: 10,
			logtotal: 0,
			avticeOrderID: 0, // 当时处理
			tabstatus: 0,
			account_number: '',	//员工账号
			common_info: {}
		};
	},
	created() {
		let data = {
			keys: ["order.payment.type.map", "order.type.map", "order.status.list",
				"order.payment.type.list",
				"order.type.list", "order.source.type.list", "goods.vr_type.list", "user.level_list",
				"express.delivery.list", "order.source.type.map", "order.refund.status.list", "order.payment.pay_type.map", "order.payment.pay_type.list", "order.source.map", "order.source.list"
			]
		}
		this.common.getCommonInfo(this, data, res => {
			this.common_info = res;
		});
	},
	mounted() {
		this.orderlistapi();
		this.citylistapi();
	},
	methods: {

		// 提取搜索数据
		extractSearch() {
			let data = {
				search: [],
				order: [{
					key: this.ordersort,
					value: this.ordersortcon
				}],
				page: this.currentPage,
				limit: this.eachPage,
			};
			if (this.orderefName) {
				let obj = {
					"key": this.orderref == 1 ? 'order_no' : "goods_name",
					"value": this.orderefName,
					"op": this.orderref == 1 ? 'search' : "search"
				}
				data.search.push(obj);
			}
			if (this.ordershopname) {
				let obj = {
					"key": this.shopnamesku == 1 ? 'goods_name' : "goods_sku",
					"value": this.ordershopname,
					"op": this.shopnamesku == 1 ? 'search' : "search"
				}
				data.search.push(obj);
			}
			if (this.ordersource) { //订单来源
				let obj = {
					"key": 'activity',
					"value": this.ordersource,
					"op": "equal"
				}
				data.search.push(obj);
				// data.activity_id=this.ordersource
			}
			if (this.ordertype) { //订单类型
				let obj = {
					"key": 'type',
					"value": this.ordertype,
					"op": "equal"
				}
				data.search.push(obj);
			}
			if (this.valuename) {
				let obj = {
					"key": this.valuesecond == 1 ? 'receiver_name' : this.valuesecond == 2 ? 'receiver_mobile' : this.valuesecond == 3 ? 'purchaser_organization_name' : '',
					"value": this.valuename,
					"op": 'search',
				}
				data.search.push(obj);
			}
			if (this.shoptype || this.shoptype === 0) { //商品类型
				let obj = {
					"key": 'vr_type',
					"value": this.shoptype,
					"op": "equal"
				}
				data.search.push(obj);
			}
			if (this.valuetimes) { //时间筛选
				let obj = {
					"key": this.ordertime,
					"value": [this.common.timeStampTurnTime(this.valuetimes[0]), this.common.timeStampTurnTime(this.valuetimes[1])],
					"op": "between"
				}
				data.search.push(obj);
			}
			if (this.servicestatus) {//售后状态
				let obj = {
					"key": "after_sale_status",
					"value": this.servicestatus,
					"op": "equal"
				}
				data.search.push(obj);
			}
			if (this.orderstatus) { //订单状态
				let obj = {
					"key": "status",
					"value": this.orderstatus,
					"op": "equal"
				}
				data.search.push(obj);
			}
			if (this.shipping) { //配送方式
				let obj = {
					"key": 'is_offline',
					"value": this.shipping,
					"op": "equal"
				}
				data.search.push(obj);
			}
			if (this.paytype) { //支付方式
				let obj = {
					"key": 'pay_type',
					"value": this.paytype,
					"op": "equal"
				}
				data.search.push(obj);
			}
			if (this.save) { //省
				let obj = {
					"key": 'province',
					"value": this.save,
					"op": "search"
				}
				data.search.push(obj);
			}
			if (this.cname) { //市
				let obj = {
					"key": 'city',
					"value": this.cname,
					"op": "search"
				}
				data.search.push(obj);
			}
			if (this.bname) { //区
				let obj = {
					"key": 'district',
					"value": this.bname,
					"op": "search"
				}
				data.search.push(obj);
			}
			if (this.vipgrade) { //会员等级
				let obj = {
					"key": 'level',
					"value": this.vipgrade,
					"op": "equal"
				}
				data.search.push(obj);
			}
			if (this.account_number) {
				let obj = {
					"key": "username",
					"value": this.account_number,
					"op": "search"
				}
				data.search.push(obj);
			}

			return data;
		},
		orderlistapi() {
			this.datatype = false;
			const data = this.extractSearch()
			let that = this;
			this.$get(this.$apis.orderRecyclelist, data).then(res => {
				// //console.log(res)
				if (res.code == 200) {
					this.tableData = res.data.list;
					this.total_entry = res.data.total;
					this.datatype = true;
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},

		addhandleChange(val) {
			this.ordersecondary = false;
			this.options.forEach((item, index) => {
				if (item.value == val) {
					if (item.type) {
						this.ordersecondary = true;
					}
				}
			})
		},
		//头部搜索时间
		ordertimechange(val) {
			if (val == 'id') {
				val = 'created_at'
			}
			this.ordertime = val;
		},
		// 时间筛选
		timechange(val) {
			this.ordersort = val;
			this.orderlistapi();
		},
		ordersorted(index) {
			this.sortIndex = index;
			if (index == 1) {
				this.ordersortcon = 'asc'
			} else {
				this.ordersortcon = 'desc'
			}
			this.orderlistapi();
		},
		// 搜索
		ordersearched() {
			this.currentPage = 1;
			this.orderlistapi();
		},
		// 重置
		resetting() {
			this.orderefName = '';
			this.ordershopname = '';
			this.ordersource = '';
			this.ordertype = '';
			this.shoptype = '';
			this.valuetimes = '';
			this.orderstatus = '';
			this.shipping = '';
			this.servicestatus = '';
			this.paytype = '';
			this.vipgrade = '';
			this.valuename = '';
			this.save = '';
			this.cname = '';
			this.bname = '';
			this.reload();
			this.orderlistapi();
		},
		// 省市区
		citylistapi() {
			this.$get(this.$apis.cityList).then(res => {
				// //console.log(res)
				if (res.code == 200) {
					this.citylisted = res.data;
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},

		choseProvince(e) {
			let that = this;
			that.city = [];
			that.block = [];
			that.cname = "";
			that.bname = "";
			for (var index2 in that.citylisted) {
				if (e === that.citylisted[index2].id) {
					that.shi1 = that.citylisted[index2].list;
					that.save = that.citylisted[index2].name;
					that.shi1.forEach(function(citem, cindex) {
						that.city.push({
							id: citem.id,
							value: citem.name,
							children: citem.list
						});
					});
				}
			}
		},

		// 选市
		choseCity(e) {
			let that = this;
			that.block = [];
			for (var index3 in that.city) {
				if (e === that.city[index3].id) {
					that.qu1 = that.city[index3].children;
					that.cname = that.city[index3].value;
					that.E = that.qu1[0].id;
					that.qu1.forEach(function(bitem, bindex) {
						that.block.push({
							id: bitem.id,
							value: bitem.name,
							children: []
						});
					});
				}
			}
		},
		// 选区
		choseBlock(e) {
			this.bname = e;
		},
		// 查看详情
		loopdetail(id) {
			this.$router.push({
				path: '/order/orderRecycle_detail',
				query: {
					id: id
				}
			});
		},

		filtertab() {
			this.is_filter = !this.is_filter;
		},
		handleSizeChange(val) {
			this.eachPage = val;
			this.orderlistapi();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			this.common.backTop();
			this.orderlistapi();
		},
		// 恢复订单
		recoverOrder(item) {
			let that = this;
			this.$confirm('确认是否要恢复此订单', '提示', {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(res => {
				let data = { id: [] };
				data.id.push(item.id);
				that.$put(that.$apis.orderRecycleRecover, data).then((result) => {
					if (result.code == 200) {
						that.$message({
							type: 'success',
							duration: 1500,
							message: '恢复成功',
							onClose: () => {
								let index = that.tableData.findIndex(i => i.id == item.id);
								that.tableData.splice(index, 1)
							}
						});
					} else {
						that.common.message(that, result.message);
					}
				}).catch((error) => {
					that.common.message(that, error.message);
				})
			}).catch((err) => { })
		},
		// 彻底删除订单
		delOrder(item) {
			let that = this;
			this.$confirm('确认是否要彻底删除此订单', '提示', {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(res => {
				let data = { id: [] };
				data.id.push(item.id);
				that.$put(that.$apis.orderRecycleDelete, data).then((result) => {
					if (result.code == 200) {
						that.$message({
							type: 'success',
							duration: 1500,
							message: '删除成功',
							onClose: () => {
								let index = that.tableData.findIndex(i => i.id == item.id);
								that.tableData.splice(index, 1)
							}
						});
					} else {
						that.common.message(that, result.message);
					}
				}).catch((error) => {
					that.common.message(that, error.message);
				})
			}).catch((err) => { })
		},

	},
};
</script>

<style scoped>
@import url("css/order.css");

.zntj {
	width: 100%;
	padding: 40px 0;
	box-sizing: border-box;
}

.zntj img {
	display: block;
	max-width: 180px;
	margin: 0 auto 10px;
}

.zntj p {
	font-size: 14px;
	color: #666;
	text-align: center;
}

.ordermanageboxbut {
	margin-top: 20px;
}

::v-deep .orderfirstleft .el-input__inner {
	height: 38px;
	line-height: 38px;
}

::v-deep .ordertimeinput .el-range-editor.el-input__inner {
	height: 38px;
}

.orderstatused p {
	text-align: center;
	font-size: 14px;
	color: #666;
}

.orderputong {
	display: inline-block;
	font-size: 14px;
	padding: 0 8px;
	box-sizing: border-box;
	line-height: 20px;
	border-radius: 2px;
	margin-right: 4px;
	background: #E9F0FF;
	color: #2F74F5;
}

.orderlastbutright {
	display: table;
	float: none;
	margin: 0 auto;
}

.ordertabelistright a {
	position: relative;
}

.ordertabelistright a i {
	display: block;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: red;
	position: absolute;
	top: 18px;
	right: -2px;
}
</style>
